import { useEffect, useState } from 'react'
import { routesConfig } from './routes/routes'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Modals from 'components/common/modals'
import ComingSoonMobile from 'components/common/coming-soon-mobile'

function App() {
  const theme = useSelector((state) => state.theme.theme)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Suppress logs in non-development environments
  if (process.env.REACT_APP_ENV !== 'development') {
    console.log = () => {}
    console.warn = () => {}
    console.error = () => {}
  }

  useEffect(() => {
    document.documentElement.className = theme
  }, [theme])

  if (isMobile) {
    return <ComingSoonMobile />
  }

  return (
    <div>
      <RouterProvider router={createBrowserRouter(routesConfig)} />
      <Modals.DownloadAppModal />
    </div>
  )
}

export default App

import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import Logo from '../../../assets/images/logo.png'
import { FaDiscord } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { FaInstagram } from 'react-icons/fa'
import { FaMedium } from 'react-icons/fa6'
import { HiGlobeAsiaAustralia } from 'react-icons/hi2'
import { BiLogoTelegram } from 'react-icons/bi'
import {
  DrawerToggleArrow,
  NFTDrawerIcon,
  OpenLinkIcon,
  RequestAFeatureDrawerIcon,
  SafleLensLightLogo,
  SafleLensLogo,
  TokenDrawerIcon,
  TransactionsDrawerIcon
} from 'assets/svg'
import { t } from 'i18next'

import './Sidebar.scss'
import { useSelector } from 'react-redux'

const NavLink = ({ title, pathToNavigate, active, icon, isExpanded, openLink }) => {
  const navigate = useNavigate()
  return (
    <li
      className={`navlink ${active ? 'navlink-active' : ''}`}
      onClick={() => (openLink ? window.open(openLink, '_blank') : navigate(pathToNavigate))}
    >
      <span className="navlink-icon">{icon}</span>
      {isExpanded && <span className="navlink-title">{title}</span>}
    </li>
  )
}

const Sidebar = () => {
  const location = useLocation()
  const [isExpanded, setIsExpanded] = useState(true)
  const theme = useSelector((state) => state.theme.theme)

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded)
  }

  // Extract `safleId` from the current route
  const safleId = location.pathname.split('/')[1]

  const navItems = [
    {
      icon: <TokenDrawerIcon />,
      title: t('MainLayout.sideBar.navItems.tokens'),
      pathToNavigate: `/${safleId}${AppRoutingConfig.TOKEN}`
    },
    {
      icon: <NFTDrawerIcon />,
      title: t('MainLayout.sideBar.navItems.nfts'),
      pathToNavigate: `/${safleId}${AppRoutingConfig.NFTS}`
    },
    {
      icon: <TransactionsDrawerIcon />,
      title: t('MainLayout.sideBar.navItems.transactions'),
      pathToNavigate: `/${safleId}${AppRoutingConfig.TRANSACTIONS}`
    },
    {
      icon: <RequestAFeatureDrawerIcon />,
      title: t('MainLayout.sideBar.navItems.requestAFeature'),
      pathToNavigate: null,
      openLink: 'https://hgbopbs0vss.typeform.com/LensFeedback'
    }
  ]

  const socialLinks = [
    {
      icon: <FaDiscord />,
      link: 'https://discord.com/invite/getsafle'
    },
    {
      icon: <FaXTwitter />,
      link: 'https://x.com/GetSafle'
    },
    {
      icon: <BiLogoTelegram />,
      link: 'https://t.me/saflechat'
    },
    {
      icon: <FaInstagram />,
      link: 'https://www.instagram.com/getsafle/'
    },
    {
      icon: <FaMedium />,
      link: 'https://safle.medium.com/'
    },
    {
      icon: <HiGlobeAsiaAustralia />,
      link: 'https://safle.com/'
    }
  ]

  const externalLinks = [
    {
      icon: <OpenLinkIcon />,
      link: 'https://safle.com/termsAndConditions',
      label: t('MainLayout.sideBar.externalLinks.terms')
    },
    {
      icon: <OpenLinkIcon />,
      link: 'https://safle.com/privacyPolicy',
      label: t('MainLayout.sideBar.externalLinks.privacy')
    }
  ]

  const currentPath = location.pathname

  return (
    <div className={`sidebar ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <button
        className={`sidebar-toggle ${isExpanded && 'rotateExpanded'}`}
        onClick={toggleSidebar}
      >
        <DrawerToggleArrow />
      </button>
      <div className="sidebar-logo">
        {isExpanded ? (
          <>
            {theme === 'light' ? (
              <div className="logo">
                <SafleLensLightLogo />
              </div>
            ) : (
              <div className="logo">
                <SafleLensLogo />
              </div>
            )}
          </>
        ) : (
          <img src={Logo} height={60} alt="Logo" />
        )}
      </div>
      <nav className="sidebar-nav">
        <ul>
          {navItems.map((item, index) => (
            <NavLink
              key={index}
              icon={item.icon}
              title={item.title}
              isExpanded={isExpanded}
              pathToNavigate={item.pathToNavigate}
              active={item.pathToNavigate === currentPath}
              openLink={item.openLink}
            />
          ))}
        </ul>
      </nav>
      <div className="sidebar-footer">
        {isExpanded && (
          <div>
            <span>{t('MainLayout.sideBar.bePartOfCommunity')}</span>
            <div className="social-media-links">
              {socialLinks.map((item, index) => (
                <a
                  className="social-media-icon"
                  key={index}
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.icon}
                </a>
              ))}
            </div>
          </div>
        )}

        <div className="terms-external-links">
          {externalLinks.map((item, index) => (
            <div
              key={index}
              className="external-links"
              onClick={() => window.open(item.link, '_blank')}
            >
              {item.icon} {isExpanded && <span>{item.label}</span>}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Sidebar

import './Button.scss'
export const Button = ({ text, BeforeIcon, AfterIcon, onClick }) => {
  return (
    <div>
      <button className="cta-button" onClick={onClick}>
        {BeforeIcon && (
          <div className="before-icon">
            <BeforeIcon />
          </div>
        )}
        {text}
        {AfterIcon && (
          <div className="after-icon">
            <AfterIcon />
          </div>
        )}
      </button>
    </div>
  )
}

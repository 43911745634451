import { useDispatch, useSelector } from 'react-redux'
import { SafleLensLightLogo, SafleLensLogo } from 'assets/svg'
import ThemeToggle from 'components/common/theme-toggle'
import { FaviconIcon } from 'assets/svg'
import { openDownloadModal } from '../../../redux/reducer/modalReducer'
import { Button } from 'components/common/button/Button'
import { useTranslation } from 'react-i18next'
import './Navbar.scss'

export const Navbar = () => {
  const { t } = useTranslation()
  const theme = useSelector((state) => state.theme.theme)
  const dispatch = useDispatch()

  const openModal = () => {
    dispatch(openDownloadModal())
  }

  return (
    <div className="navbar-home">
      <div className="home-logo-container">
        {theme === 'light' ? (
          <div className="logo">
            <SafleLensLightLogo />
          </div>
        ) : (
          <div className="logo">
            <SafleLensLogo />
          </div>
        )}
      </div>

      <div className="actions">
        <div className="download-button">
          <Button text={t('Navbar.downloadButton')} BeforeIcon={FaviconIcon} onClick={openModal} />
        </div>
        <ThemeToggle />
      </div>
    </div>
  )
}

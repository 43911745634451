import { createSlice } from '@reduxjs/toolkit'
import {
  checkUser,
  supportedChains,
  assetsOnChains,
  fetchConversionRate
} from '../services/authService'

const initialState = {
  loading: false,
  error: null,
  conversionError: null,
  supportedChainsError: null,
  assetsOnChainsError: null,
  user: {
    address: '',
    safleId: '',
    ENS: ''
  },
  chains: [],
  conversionRate: 1,
  refresh: 0,
  tokenRefreshTime: 0,
  nftRefreshTime: 0,
  txnRefreshTime: 0,
  activeCurrency: 'usd',
  chainWorth: [],
  activeChains: [],
  tokenFilters: [],
  transactionTypes: ['all'],
  dateFilter: []
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearUserData: (state) => {
      state.loading = false
      state.user = initialState.user
      state.error = null
      state.chains = []
      state.chainWorth = []
      state.conversionRate = 1
    },
    clearError: (state) => {
      state.error = null
    },
    setDefaultConversionRate: (state) => {
      state.conversionRate = 1
    },
    setActiveCurrency: (state, action) => {
      state.activeCurrency = action.payload
    },
    setRefresh: (state, action) => {
      state.refresh = action.payload
    },
    setActiveChains: (state, action) => {
      state.activeChains = action.payload
    },
    setTokenFilters: (state, action) => {
      state.tokenFilters = action.payload
    },
    setVisibleNFTs: (state, action) => {
      state.visibleNFTs = action.payload
    },
    setHasMore: (state, action) => {
      state.hasMore = action.payload
    },
    setTransactionTypes: (state, action) => {
      state.transactionTypes = action.payload
    },
    setDateFilter: (state, action) => {
      state.dateFilter = action.payload
    }
  },
  extraReducers: (builder) => {
    // Check User
    builder.addCase(checkUser.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(checkUser.fulfilled, (state, action) => {
      state.loading = false
      if (action.meta.arg.updateUserState) {
        state.user = action.payload
      }
    })
    builder.addCase(checkUser.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload || 'Unable to fetch user'
    })

    // Supported Chains
    builder.addCase(supportedChains.pending, (state) => {
      state.loading = true
      state.supportedChainsError = null
    })
    builder.addCase(supportedChains.fulfilled, (state, action) => {
      state.loading = false
      state.chains = action.payload
      state.activeChains = action.payload?.map((chain) => chain?.name?.toLowerCase())
    })
    builder.addCase(supportedChains.rejected, (state, action) => {
      state.loading = false
      state.supportedChainsError = action.payload
    })

    // Assets on Chains
    builder.addCase(assetsOnChains.pending, (state) => {
      state.loading = true
      state.assetsOnChainsError = null
    })
    builder.addCase(assetsOnChains.fulfilled, (state, action) => {
      state.loading = false
      const { chain, data } = action.payload
      const existingIndex = state.chainWorth.findIndex((item) => item.chain === chain)

      if (existingIndex !== -1) {
        state.chainWorth[existingIndex] = { chain, ...data }
      } else {
        state.chainWorth.push({ chain, ...data })
      }
    })
    builder.addCase(assetsOnChains.rejected, (state, action) => {
      state.loading = false
      state.assetsOnChainsError = action.payload
    })

    // Fetch Conversion Rate
    builder.addCase(fetchConversionRate.pending, (state) => {
      state.loading = true
      state.conversionError = null
    })
    builder.addCase(fetchConversionRate.fulfilled, (state, action) => {
      state.loading = false
      state.conversionRate = action.payload
    })
    builder.addCase(fetchConversionRate.rejected, (state, action) => {
      state.loading = false
      state.conversionError = action.payload
      state.conversionRate = 1
    })
  }
})

// Action creators are generated for each case reducer function
export const {
  clearUserData,
  setDefaultConversionRate,
  setActiveCurrency,
  setRefresh,
  setActiveChains,
  setTokenFilters,
  setVisibleNFTs,
  setHasMore,
  setTransactionTypes,
  setDateFilter,
  clearError
} = authSlice.actions

export default authSlice.reducer

import { useState } from 'react'
import { SafleLensLightLogo, SafleLensLogo } from 'assets/svg'
import { FaDiscord, FaInstagram, FaMedium } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { HiGlobeAsiaAustralia } from 'react-icons/hi2'
import { BiLogoTelegram } from 'react-icons/bi'
import axios from 'axios'

import { useSelector } from 'react-redux'
import './Footer.scss'
import { Button } from '../button/Button'

// Environment variables
const AIRTABLE_API_URL = process.env.REACT_APP_AIRTABLE_API_URL
const AIRTABLE_API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY

// Footer links data
const footerLinks = [
  {
    key: 'Safleverse',
    value: [
      {
        key: 'SafleID',
        link: 'https://safle.com/safleId'
      },
      {
        key: 'Wallet Utility',
        link: 'https://safle.com/walletUtility'
      },
      {
        key: 'Safle Vault',
        link: 'https://safle.com/safleVault'
      }
    ]
  },
  {
    key: '$SAFLE Token',
    value: [
      {
        key: 'Tokenomics',
        link: 'https://safle.com/tokenomics'
      },
      {
        key: 'Roadmap',
        link: 'https://safle.com/roadmap'
      }
    ]
  },
  {
    key: 'Developers',
    value: [
      {
        key: 'Developer Suite',
        link: 'https://safle.com/developerSuite'
      },
      {
        key: 'Safle Keyless',
        link: 'https://safle.com/safleKeyless'
      }
    ]
  },
  {
    key: 'About',
    value: [
      {
        key: 'Mission',
        link: 'https://safle.com/mission'
      },
      {
        key: 'Crew',
        link: 'https://safle.com/crew'
      },
      {
        key: 'Careers',
        link: 'https://safle.com/careers'
      },
      {
        key: 'Whitepaper',
        link: 'https://safle.com/whitepaper'
      },
      {
        key: 'Contact Us',
        link: 'https://safle.com/contactUs'
      }
    ]
  },
  {
    key: 'Policies',
    value: [
      {
        key: 'Privacy Policy',
        link: 'https://safle.com/privacyPolicy'
      },
      {
        key: 'Terms & Conditions',
        link: 'https://safle.com/termsAndConditions'
      }
    ]
  }
]

// Social links data for easier management
const socialLinks = [
  {
    icon: <FaDiscord size={24} />,
    onClick: () =>
      window.open('https://discord.com/invite/getsafle', '_blank', 'noopener,noreferrer')
  },
  {
    icon: <FaXTwitter size={24} />,
    onClick: () => window.open('https://x.com/GetSafle', '_blank', 'noopener,noreferrer')
  },
  {
    icon: <BiLogoTelegram size={24} />,
    onClick: () => window.open('https://t.me/saflechat', '_blank', 'noopener,noreferrer')
  },
  {
    icon: <FaInstagram size={24} />,
    onClick: () =>
      window.open('https://www.instagram.com/getsafle/', '_blank', 'noopener,noreferrer')
  },
  {
    icon: <FaMedium size={24} />,
    onClick: () => window.open('https://safle.medium.com/', '_blank', 'noopener,noreferrer')
  },
  {
    icon: <HiGlobeAsiaAustralia size={24} />,
    onClick: () => window.open('https://safle.com/', '_blank', 'noopener,noreferrer')
  }
]

const Footer = () => {
  const theme = useSelector((state) => state.theme.theme)
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubscribe(e)
    }
  }

  const handleSubscribe = async (e) => {
    e.preventDefault()
    setError('')
    setSuccess('')

    if (!validateEmail(email)) {
      setError('Please enter a valid email address.')
      setTimeout(() => {
        setError('')
      }, 2000)
      return
    }

    try {
      const authHeader = `Bearer ${AIRTABLE_API_KEY}`
      const response = await axios.post(
        AIRTABLE_API_URL,
        { fields: { Email: email } },
        {
          headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json'
          }
        }
      )
      if (response.status === 200) {
        setSuccess('Successfully subscribed!')
        setEmail('')
        setTimeout(() => setSuccess(''), 3000)
      } else {
        setError('Error subscribing. Please try again.')
      }
    } catch (err) {
      setError('Error subscribing. Please try again.')
    }
  }

  const validateEmail = (emailString) => {
    return String(emailString)
      .toLowerCase()
      .match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
  }

  return (
    <div className="footer">
      {/* Logo Section */}
      <div className="footer-logo">
        {theme === 'light' ? <SafleLensLightLogo /> : <SafleLensLogo />}
      </div>

      <div className="footer-content">
        {/* Footer Links */}
        <div className="footer-links">
          {footerLinks.map((section) => (
            <div className="footer-section" key={section.key}>
              <h4>{section.key}</h4>
              {section.value.map((linkItem) => (
                <a
                  href={linkItem.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={linkItem.key}
                >
                  {linkItem.key}
                </a>
              ))}
            </div>
          ))}
        </div>

        {/* Community & Social Icons */}
        <div className="footer-community">
          <h4>Be a part of Safle Community</h4>
          <div className="footer-social">
            {socialLinks.map((item, idx) => (
              <div key={idx} className="footer-social-icon" onClick={item.onClick}>
                {item.icon}
              </div>
            ))}
          </div>

          {/* Newsletter Subscription */}
          <div className="footer-subscription">
            <h4>Subscribe to our Newsletter</h4>
            <p>Stay updated with the latest news & new feature updates.</p>
            <div className="footer-subscribe-input">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyPress}
                required
              />
              <Button onClick={handleSubscribe} text={'Subscribe'} />
            </div>
            {error && <div className="footer-error">{error}</div>}
            {success && <div className="footer-success">{success}</div>}
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        © {new Date().getFullYear()} Safle Lens. All rights reserved.
      </div>
    </div>
  )
}

export default Footer

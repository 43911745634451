import { useEffect, useState } from 'react'
import Header from 'components/common/header'
import Sidebar from 'components/common/sidebar'
import SpinLogo from 'components/common/spin-logo'
import SafleAI from 'components/common/safle-ai'
import { Outlet, useParams } from 'react-router-dom'
import { SafleAIIcon } from 'assets/svg'
import './MainLayout.scss'
import {
  assetsOnChains,
  checkUser,
  fetchConversionRate,
  supportedChains
} from '../../../redux/services/authService'
import { useDispatch, useSelector } from 'react-redux'
import { setDefaultConversionRate } from '../../../redux/reducer/authReducer'

import {
  setChatContext,
  setActiveAIToken,
  setIsAIActive
} from '../../../redux/reducer/safleAiReducer'

const MainLayout = () => {
  const params = useParams()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000) // Simulate loading
  }, [])

  const dispatch = useDispatch()
  const { user, chains, activeCurrency, refresh } = useSelector((state) => state.auth)
  const { isAIActive } = useSelector((state) => state.safleAI)
  const walletId = params?.safleId

  useEffect(() => {
    async function initializeUser() {
      const userData = await dispatch(checkUser({ walletId, updateUserState: true }))
      if (typeof userData !== 'string') {
        dispatch(supportedChains())
      }
    }

    initializeUser()
  }, [dispatch, walletId, refresh])

  useEffect(() => {
    async function fetchAssets() {
      if (chains.length > 0 && user?.address) {
        const assetFetchPromises = chains?.map((chain) =>
          dispatch(
            assetsOnChains({
              chain: chain.name.toLowerCase(),
              address: user.address
            })
          )
        )

        // Wait for all dispatch calls to complete
        await Promise.all(assetFetchPromises)
      }
    }

    fetchAssets()
  }, [dispatch, chains, user])

  useEffect(() => {
    if (activeCurrency.toUpperCase() !== 'USD') {
      dispatch(fetchConversionRate(activeCurrency.toUpperCase()))
    } else {
      dispatch(setDefaultConversionRate())
    }
  }, [dispatch, activeCurrency])

  const handleAIClick = () => {
    dispatch(setIsAIActive(true))
    if (location.pathname.includes('tokens')) {
      dispatch(setChatContext('tokenPage'))
    } else if (location.pathname.includes('nfts')) {
      dispatch(setChatContext('nftPage'))
    } else if (location.pathname.includes('transactions')) {
      dispatch(setChatContext('txnPage'))
    } else {
      dispatch(setChatContext('general'))
    }
    setActiveAIToken(null)
  }

  if (loading) {
    return (
      <div className="main-layout-loading">
        <SpinLogo />
      </div>
    )
  }

  return (
    <div className="main-layout-layout">
      <Sidebar />
      <div className="main-layout-content">
        <Header />
        <div className="main-layout-main">
          <Outlet />
        </div>
      </div>

      {!isAIActive && (
        <span className="main-layout-ai-icon" onClick={handleAIClick} title="Open AI Assistant">
          <SafleAIIcon />
        </span>
      )}

      {isAIActive && <SafleAI />}
    </div>
  )
}

export default MainLayout

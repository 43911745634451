import { createSlice } from '@reduxjs/toolkit'
import { fetchTxns } from '../services/txnService'

const initialState = {
  txns: [],
  loading: false,
  error: null
}

const txnSlice = createSlice({
  name: 'txns',
  initialState,
  reducers: {
    restartState: (state) => {
      state.txns = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTxns.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchTxns.fulfilled, (state, action) => {
      state.loading = false
      let txnWithChains = action.payload?.transactions?.length
        ? action.payload.transactions.map((txn) => ({
            ...txn,
            chain: action.payload.chain
          }))
        : []

      state.txns = [...state.txns, ...txnWithChains]
    })
    builder.addCase(fetchTxns.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  }
})

export const { restartState } = txnSlice.actions

export default txnSlice.reducer

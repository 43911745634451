import { useDispatch, useSelector } from 'react-redux'
import { MdLightMode, MdDarkMode } from 'react-icons/md'
import { toggleTheme } from '../../../redux/reducer/themeReducer'

import './ThemeToggle.scss'

const ThemeToggle = () => {
  const theme = useSelector((state) => state.theme.theme)
  const dispatch = useDispatch()

  const handleToggleTheme = () => {
    dispatch(toggleTheme())
  }
  return (
    <>
      {theme === 'light' ? (
        <div onClick={handleToggleTheme} className="theme-toggle">
          <MdDarkMode />
        </div>
      ) : (
        <div onClick={handleToggleTheme} className="theme-toggle">
          <MdLightMode />
        </div>
      )}
    </>
  )
}

export default ThemeToggle

import { RightArrow, SearchIcon } from 'assets/svg'
import './Searchbar.scss'
import MoonLoader from '../moon-loader'

const Searchbar = ({
  handleInputChange,
  handleSubmit,
  error,
  success,
  loading,
  buttonDisabled,
  showLoader = true
}) => {
  return (
    <>
      <div className="search-bar">
        <div className="input-container">
          <div className={`search-icon`} onClick={handleSubmit}>
            <SearchIcon />
          </div>
          <input
            type="text"
            placeholder="Enter SafleID, Address or ENS"
            onChange={handleInputChange}
            className={error ? 'error' : success ? 'success' : ''}
          />

          <div className={`icon ${buttonDisabled ? 'disabled' : ''}`} onClick={handleSubmit}>
            <RightArrow />
          </div>
        </div>

        {error && <div className="feedback-message error">{error}</div>}
        {success && <div className="feedback-message success">{success}</div>}
        {showLoader && loading && (
          <div className="feedback-message">
            <MoonLoader />
          </div>
        )}
      </div>
    </>
  )
}

export default Searchbar

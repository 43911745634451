import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  downloadAppModal: false
}

export const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openDownloadModal: (state) => {
      state.downloadAppModal = true
    },
    closeDownloadModal: (state) => {
      state.downloadAppModal = false
    }
  }
})

// Action creators are generated for each case reducer function
export const { openDownloadModal, closeDownloadModal } = modalSlice.actions

export default modalSlice.reducer

import { createSlice } from '@reduxjs/toolkit'
import { fetchAIResponse } from '../services/safleAiService'

const aiSlice = createSlice({
  name: 'ai',
  initialState: {
    isAIActive: false,
    chatContext: '',
    activeAIToken: null,
    chatHistory: [],
    streamingResponse: '',
    chatDisabled: false,
    error: null
  },
  reducers: {
    setIsAIActive(state, action) {
      state.isAIActive = action.payload
    },
    setChatContext(state, action) {
      state.chatContext = action.payload
    },
    setActiveAIToken(state, action) {
      state.activeAIToken = action.payload
    },
    addChatMessage(state, action) {
      state.chatHistory.push(action.payload)
    },
    clearChat(state) {
      state.chatHistory = []
      state.chatContext = ''
      state.activeAIToken = null
      state.streamingResponse = ''
      state.error = null
    },
    setStreamingResponse(state, action) {
      state.streamingResponse = action.payload
    },
    setChatDisabled(state, action) {
      state.chatDisabled = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAIResponse.pending, (state) => {
        state.chatDisabled = true
        state.error = null
      })
      .addCase(fetchAIResponse.fulfilled, (state, action) => {
        state.chatHistory.push({ role: 'assistant', content: action.payload })
        state.streamingResponse = ''
        state.chatDisabled = false
      })
      .addCase(fetchAIResponse.rejected, (state, action) => {
        state.streamingResponse = action.payload || 'An error occurred.'
        state.chatDisabled = false
      })
  }
})

export const {
  setIsAIActive,
  setChatContext,
  setActiveAIToken,
  addChatMessage,
  clearChat,
  setStreamingResponse,
  setChatDisabled,
  setError
} = aiSlice.actions

export default aiSlice.reducer

import axios from 'axios'
import { BASE_URL } from '../constants'
// import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'

const axiosInstance = axios.create({
  baseURL: window.location.hostname === 'localhost' ? BASE_URL : process.env.REACT_APP_USERAPI
})

// axiosInstance.defaults.withCredentials = true

/* Add a request interceptor */
// const requestInterceptor = (config) => {
//   config.data = {
//     payloadData: {
//       requestData: config.data
//     }
//   }
//   return config
// }

// axiosInstance.interceptors.request.use(requestInterceptor)
/* Add a request interceptor */

/* Add a response interceptor */
// const responseInterceptor = (responseData) => {
//   if (responseData?.status === 401) {
//     // window.location.replace(AppRoutingConfig.APP_URL_LOGIN);
//     localStorage.clear()
//   }
//   console.log('responseData', responseData)
//   let response = responseData.data
//   return { response }
// }

// axiosInstance.interceptors.response.use(responseInterceptor)
/* Add a response interceptor */

export default axiosInstance

import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'

// Define the thunk to fetch NFTs
export const fetchNFTs = createAsyncThunk(
  'nfts/fetchNFTs',
  async ({ chain, address }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`v1/asset/nft/${chain}/${address}`)
      return response.data.data
    } catch (error) {
      console.error('Error fetching NFTs:', error)
      return rejectWithValue(error.response.data)
    }
  }
)

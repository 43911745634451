import { createAsyncThunk } from '@reduxjs/toolkit'

const REACT_APP_SAFLE_AI_API = process.env.REACT_APP_SAFLE_AI_API

export const fetchQueryResponse = async (messages) => {
  const response = await fetch(`${REACT_APP_SAFLE_AI_API}/v1/query`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ messages })
  })

  if (!response.ok) {
    const errorMsg = await response.json()
    throw new Error(errorMsg?.error?.message)
  }

  return response.body
}

export const fetchAIResponse = createAsyncThunk(
  'ai/fetchAIResponse',
  async (_, { getState, rejectWithValue }) => {
    const state = getState().ai
    const { chatHistory, chatContext, activeAIToken } = state

    // Helper functions
    const filterPayload = (payload) => {
      const filteredPayload = []
      let lastRole = null

      for (let i = payload.length - 1; i >= 0; i--) {
        const current = payload[i]

        if (current.role !== lastRole) {
          filteredPayload.unshift(current)
          lastRole = current.role
        } else if (current.role === 'assistant') {
          filteredPayload.unshift(current)
        }
      }

      return filteredPayload
    }

    const getLast10Messages = () => {
      let recentMessages = chatHistory.slice(-10)
      if (chatContext === 'token') {
        recentMessages = recentMessages.map((obj, i) =>
          i === recentMessages.length - 1
            ? {
                ...obj,
                content: obj.content.replace(
                  /token/g,
                  `${activeAIToken?.contract_name} (${activeAIToken?.contract_ticker_symbol})`
                )
              }
            : obj
        )
      }
      if (chatContext === 'nft') {
        recentMessages = recentMessages.map((obj, i) =>
          i === recentMessages.length - 1
            ? {
                ...obj,
                content: obj.content.replace(
                  /NFT/g,
                  `NFT of ${activeAIToken?.description || activeAIToken?.token_id} (${activeAIToken?.asset_contract} on ${activeAIToken?.chainName} chain)`
                )
              }
            : obj
        )
      }
      return recentMessages
    }

    try {
      const response = await fetchQueryResponse(filterPayload(getLast10Messages()))

      const reader = response.getReader()
      const decoder = new TextDecoder()

      let responseText = ''
      //eslint-disable-next-line
      while (true) {
        const { done, value } = await reader.read()
        if (done) break
        const chunk = decoder.decode(value, { stream: true })

        const dataLines = chunk.split('\n')
        dataLines.forEach((line) => {
          if (line.trim().startsWith('data:')) {
            const jsonStr = line.trim().slice(5)
            if (jsonStr) {
              try {
                const jsonObj = JSON.parse(jsonStr)
                const content = jsonObj.choices[0]?.delta?.content
                if (content) {
                  responseText += content
                }
              } catch (error) {
                console.error('Error parsing JSON:', error)
              }
            }
          }
        })
      }

      return responseText
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred')
    }
  }
)

import { useNavigate } from 'react-router-dom'
import './ErrorBoundary.scss'

export default function ErrorBoundary() {
  const navigate = useNavigate()

  return (
    <div className="error-boundary-container">
      <div className="error-boundary-flex">
        <div className="error-boundary-content">
          <h2>Something Went Wrong</h2>
          <h3>Sorry for the inconvenience</h3>
          <h3>Our team has been notified</h3>
          <button className="error-boundary-button" onClick={() => navigate('/')}>
            Go to Home
          </button>
        </div>
      </div>
    </div>
  )
}

import { useLocation, useNavigate } from 'react-router-dom'
import ThemeToggle from 'components/common/theme-toggle'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { openDownloadModal } from '../../../redux/reducer/modalReducer'
import { Button } from '../button/Button'
import { FaviconIcon } from 'assets/svg'
import { isAddress, getAddress as toChecksumAddress } from 'ethers'
import Searchbar from '../search-bar'
import { checkUser } from '../../../redux/services/authService'
import { useState, useEffect } from 'react'
import { clearError } from '../../../redux/reducer/authReducer'
// import { clearUserData } from '../../../redux/reducer/authReducer'
import './Header.scss'

const Header = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState('')
  const [debouncedQuery, setDebouncedQuery] = useState('') // Add state for debounced query

  const { loading, error, userData } = useSelector((state) => state.auth)

  const success = userData && (userData.address || userData.safleId || userData.ENS)

  const buttonDisabled = !searchQuery || loading || error

  const handleInputChange = (e) => {
    const walletId = e.target.value.trim()
    setSearchQuery(walletId)
  }

  // Debounce logic
  useEffect(() => {
    let timer
    if (searchQuery === '') {
      dispatch(clearError())
      // Clear error state if there is no search query
    } else {
      timer = setTimeout(() => {
        setDebouncedQuery(searchQuery)
      }, 1200) // 1.2s debounce interval
    }

    return () => clearTimeout(timer) // Cleanup timer on component unmount or query change
    // eslint-disable-next-line
  }, [searchQuery])

  // Dispatch API call based on debounced query
  useEffect(() => {
    if (debouncedQuery) {
      const validId = isAddress(debouncedQuery) ? toChecksumAddress(debouncedQuery) : debouncedQuery
      dispatch(checkUser({ walletId: validId, updateUserState: false }))
    }
  }, [debouncedQuery, dispatch])

  const handleSubmit = () => {
    if (!buttonDisabled) navigate(`/${searchQuery}`)
  }

  const openModal = () => {
    dispatch(openDownloadModal())
  }

  const routeTitles = {
    tokens: 'Tokens',
    nfts: 'NFTs',
    transactions: 'Transactions'
  }

  const currentPath = location.pathname.split('/').pop()
  const pageTitle = routeTitles[currentPath] || ''
  return (
    <div className="navbar">
      <div className="navbar-title">
        <h3>{pageTitle}</h3>
      </div>
      <div className="navbar-search">
        <Searchbar
          buttonDisabled={buttonDisabled}
          error={error}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          loading={loading}
          success={success}
          showLoader={false}
        />
      </div>
      <div className="navbar-actions">
        <div className="download-button">
          <Button text={t('Navbar.downloadButton')} BeforeIcon={FaviconIcon} onClick={openModal} />
        </div>
        <ThemeToggle />
      </div>
    </div>
  )
}

export default Header

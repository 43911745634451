import { useSelector } from 'react-redux'
import Logo from '../../../assets/images/logo.png'
import './SpinLogo.scss'

const SpinLogo = () => {
  const theme = useSelector((state) => state.theme.theme)

  return (
    <div className={`gradient-container ${theme === 'dark' && 'dark-spin'}`}>
      <div className="rotating-gradient">
        <div className="gradient-background"></div>
      </div>
      <div className="logo-container">
        <img src={Logo} height={150} alt="Logo" />
      </div>
    </div>
  )
}

export default SpinLogo

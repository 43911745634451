export class AppRoutingConfig {
  static APP_URL_ROOT = '/'
  static SAFLEID = '/:safleId'
  static TOKEN = '/tokens'
  static TOKEN_ROUTE = '/:safleId/tokens'
  static NFTS = '/nfts'
  static NFTS_ROUTE = '/:safleId/nfts'
  static TRANSACTIONS = '/transactions'
  static TRANSACTIONS_ROUTE = '/:safleId/transactions'

  static APP_URL_LOGIN = '/login'
  static APP_URL_DEFAULT_BASE_REDIRECT = '*'
}

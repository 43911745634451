import { lazy } from 'react'

// export const Login = lazy(() => import('../../components/pages/auth/login'))

export const HomeScreen = lazy(() => import('../../components/pages/features/home-screen'))

export const TokenScreen = lazy(() => import('../../components/pages/features/token-screen'))

export const NFTScreen = lazy(() => import('../../components/pages/features/nft-screen'))
export const TxnScreen = lazy(() => import('../../components/pages/features/txn-screen'))

// page not found
export const PageNotFound = lazy(() => import('../../components/pages/pageNotFound'))

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'

import axios from 'axios'
import { Provider } from 'react-redux'
import store from './redux/store/store'
import { BASE_URL } from './constants'
import LanguageProvider from './context/LanguageProvider'
import { HelmetProvider } from 'react-helmet-async'
import reportWebVitals from './reportWebVitals'
import SpinLogo from 'components/common/spin-logo'

import App from 'app/App'
import './i18n/i18n'
import './axiosInstance'

import './index.scss'
axios.defaults.baseURL = BASE_URL

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <LanguageProvider>
      <Provider store={store}>
        <HelmetProvider>
          <Suspense fallback={<SpinLogo />}>
            <App />
          </Suspense>
        </HelmetProvider>
      </Provider>
    </LanguageProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

export const questions = {
  general: [],
  tokenPage: [
    'How many Cryptos are there in the market currently? What are the different categories and standards?',
    'What are some promising new cryptocurrencies to watch?',
    'Can you summarize the overall crypto market trend this week?',
    'How dominant is Bitcoin in the current market?',
    'What are the major upcoming events in the cryptocurrency world?'
  ],
  nftPage: [
    'Which NFT marketplace has been used most frequently for purchases?',
    'Which category of NFTs (e.g., art, gaming, metaverse) is most traded?',
    'What is the rarity score of the NFT? What are such different metric to benchmark?',
    'What are some promising new NFTs to watch?',
    'What are the different NFT standards?'
  ],
  txnPage: [
    'Which DEX or exchange or dApp is most frequently used for transactions?',
    'How many transactions are processed on the Bitcoin network daily?',
    'How many transactions per second can different EVM networks handle?',
    'What is the transaction fees on different EVM and non-EVM networks?',
    'How many cross-chain transactions occur daily?'
  ],
  token: [
    'Can you provide a brief overview of this token, including its purpose and utility?',
    'Where can I buy or trade this token?',
    'Which dApps support this token? What can I do with this token on these dApps?',
    'What is the total supply and circulating supply of this token?',
    'Are there any notable events or updates related to this token?'
  ],
  nft: [
    'Can you provide a brief overview of this NFT, including its creator and collection?',
    'Can you explain the utility or perks associated with this NFT?',
    'What marketplaces support trading for this NFT?',
    'What are the historical sales prices for this NFT?',
    'Can you compare this NFT to similar NFTs in the market?'
  ]
}

import { useSelector } from 'react-redux'

import PopUp from 'assets/svg/home-page-icons/spaceship interior 1.png'
import Phone from 'assets/svg/home-page-icons/phone2.png'
import Phone1 from 'assets/svg/home-page-icons/phone.png'
import QRcode from 'assets/svg/home-page-icons/Frame37217.svg'
import QRcodeDark from 'assets/svg/home-page-icons/Frame37217 dark.svg'
import Close from 'assets/svg/cross.svg'

import './DownloadAppImage.scss'

export const DownloadAppPopUpImage = () => {
  const theme = useSelector((state) => state.theme.theme)

  return (
    <div className="download-popup">
      {/* Background Layer */}
      <div className="download-popup__bg-layer">
        {/* Semi-transparent overlay */}
        <div className="download-popup__bg-overlay"></div>

        {/* Blur + shadow overlay */}
        <div className="download-popup__bg-blur"></div>

        {/* Main image */}
        <img className="download-popup__bg-image" src={PopUp} alt="popup" />
      </div>

      {/* Text Content */}
      <div className="download-popup__title">
        Safe and Simple
        <br />
        Access to Cryptocurrencies.
      </div>

      {/* Phones & QR */}
      <div className="download-popup__phones-qr">
        <img
          src={theme === 'light' ? QRcodeDark : QRcode}
          alt="qr-code"
          className="download-popup__qrcode"
        />
        <img src={Phone1} alt="mobile-1" className="download-popup__phone1" />
        <img src={Phone} alt="mobile-2" className="download-popup__phone2" />
      </div>

      {/* Close Icon */}
      <div className="download-popup__close-icon">
        <img src={Close} alt="close" />
      </div>
    </div>
  )
}

export default DownloadAppPopUpImage

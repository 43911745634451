import Footer from 'components/common/footer'
import Navbar from 'components/common/navbar'
import { Outlet } from 'react-router-dom'

import './HomeLayout.scss'

const HomeLayout = () => {
  return (
    <div className="page-container">
      <div className="header">
        <Navbar />
      </div>
      <Outlet />
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  )
}

export default HomeLayout

import ErrorBoundary from 'components/common/error-boundry'
import * as lazy from './lazyComponents'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import MainLayout from 'components/layouts/main-layout'
import { Navigate, useParams } from 'react-router-dom'
import HomeLayout from 'components/layouts/home-layout'

const DynamicRedirect = ({ to }) => {
  const { safleId } = useParams()
  return <Navigate to={`/${safleId}${to}`} replace />
}

export const routesConfig = [
  {
    path: AppRoutingConfig.APP_URL_ROOT,
    element: <HomeLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true, // Makes this the default child route
        element: <lazy.HomeScreen />
      }
    ]
  },

  {
    path: AppRoutingConfig.SAFLEID,
    element: <MainLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true, // Makes this the default child route
        element: <DynamicRedirect to={AppRoutingConfig.TOKEN} />
      },
      {
        path: AppRoutingConfig.TOKEN_ROUTE,
        element: <lazy.TokenScreen />
      },
      {
        path: AppRoutingConfig.NFTS_ROUTE,
        element: <lazy.NFTScreen />
      },
      {
        path: AppRoutingConfig.TRANSACTIONS_ROUTE,
        element: <lazy.TxnScreen />
      }
    ]
  },

  {
    path: AppRoutingConfig.APP_URL_DEFAULT_BASE_REDIRECT,
    element: <lazy.PageNotFound />
  }
]

import { useDispatch, useSelector } from 'react-redux'
import { closeDownloadModal } from '../../../../redux/reducer/modalReducer'
import DownloadAppPopUpImage from './DownloadAppImage'

const DownloadAppModal = () => {
  const isModalOpen = useSelector((state) => state.modal.downloadAppModal)
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(closeDownloadModal())
  }
  return (
    <div>
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <DownloadAppPopUpImage />
        </div>
      )}
    </div>
  )
}

export default DownloadAppModal

import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'

// Define the thunk to fetch Txns
export const fetchTxns = createAsyncThunk('txns/fetchTxns', async ({ chain, address }) => {
  try {
    const response = await axiosInstance.get(`v1/transactions/${chain}/${address}`) // Use backticks here
    return response.data.data
  } catch (error) {
    console.error('Error fetching Txns:', error)
  }
})

import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
import { isENS } from 'utils/isENS'
import { isPublicAddress } from 'utils/isPublicAddress'

// A) Check if address is valid
export const isAddressValid = createAsyncThunk(
  'user/isAddressValid',
  async (address, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`v1/address/is-address-valid/${address}`)
      return response.data.data.isValid
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Error validating address')
    }
  }
)

export const resolveEns = createAsyncThunk('user/resolveEns', async (ens, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`v1/address/resolve-ens/${ens}`)
    return response.data.data.publicAddress // string
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'Error resolving ENS')
  }
})

export const resolveAddress = createAsyncThunk(
  'user/resolveAddress',
  async (address, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`v1/address/resolve-address/${address}`)
      return response.data.safleID // string
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Error resolving address')
    }
  }
)

export const resolveSafleId = createAsyncThunk(
  'user/resolveSafleId',
  async (safleId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`v1/address/resolve-safleid/${safleId}`)
      return response.data.data.publicAddress // string
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'SafleID not found')
    }
  }
)

// B) Check User
export const checkUser = createAsyncThunk(
  'user/checkUser',
  async ({ walletId, updateUserState }, { dispatch, rejectWithValue }) => {
    let user = {
      address: '',
      safleId: '',
      ENS: ''
    }

    try {
      // 1) Check if it's a valid public address
      if (isENS(walletId)) {
        try {
          // Resolve ENS to public address Else check if it's an ENS
          const publicAddress = await dispatch(resolveEns(walletId)).unwrap()
          if (updateUserState) {
            user.ENS = walletId
            user.address = publicAddress
          }
        } catch (error) {
          return rejectWithValue(error || 'Error resolving ENS.')
        }
      }
      // 2)
      else if (isPublicAddress(walletId)) {
        // Dispatch isAddressValid and wait for the result
        const isValid = await dispatch(isAddressValid(walletId)).unwrap()
        if (isValid) {
          // Resolve the SafleID
          const safleId = await dispatch(resolveAddress(walletId)).unwrap()
          if (updateUserState) {
            user.safleId = safleId
            user.address = walletId
          }
        } else {
          // Not a valid public address
          return rejectWithValue('Invalid public address.')
        }
      }
      // 3) Otherwise treat as SafleID
      else {
        try {
          // Resolve SafleID
          const publicAddress = await dispatch(resolveSafleId(walletId)).unwrap()

          if (updateUserState) {
            user.safleId = walletId
            user.address = publicAddress
          }
        } catch (error) {
          return rejectWithValue(error || 'SafleID not found')
        }
      }

      return user
    } catch (error) {
      // This will catch errors from isAddressValid
      return rejectWithValue(error || 'SafleID not found')
    }
  }
)

// Additional Thunks
export const supportedChains = createAsyncThunk(
  'user/supportedChains',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('v1/supported-chains')
      return response.data.data.chains
    } catch (error) {
      return rejectWithValue('Error fetching supported chains')
    }
  }
)

export const assetsOnChains = createAsyncThunk(
  'user/assetsOnChains',
  async ({ chain, address }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`v1/asset/tokens/${chain}/${address}`)
      return {
        chain,
        data: response.data.data
      }
    } catch (error) {
      return rejectWithValue(`Error fetching assets for chain: ${chain}`)
    }
  }
)

export const fetchConversionRate = createAsyncThunk(
  'user/fetchConversionRate',
  async (symbol, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`v1/asset/conversion-rate/${symbol}`)
      return response.data.data.conversion_rate
    } catch (error) {
      return rejectWithValue('Error fetching conversion rate')
    }
  }
)

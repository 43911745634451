import { useState, useCallback, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactMarkdown from 'react-markdown'
import { questions } from 'constants'
import { fetchQueryResponse } from '../../../redux/services/safleAiService'

import CloseIcon from 'assets/svg/close.svg'
import AIICON2 from 'assets/svg/AI-icon-2.svg'
import AI_ICON_LIGHT from 'assets/svg/AI_ICON_LIGHT-1.svg'
import NFTnotFound from 'assets/svg/NFT-Placeholder.svg'
import {
  setIsAIActive,
  setChatContext,
  setActiveAIToken,
  addChatMessage,
  clearChat
} from '../../../redux/reducer/safleAiReducer'

import './SafleAI.scss'

const SafleAI = () => {
  const dispatch = useDispatch()
  const { chatHistory, chatContext, activeAIToken } = useSelector((state) => state.safleAI)
  const theme = useSelector((state) => state.theme.theme)

  const [activeQuery, setActiveQuery] = useState('')
  const [streamingResponse, setStreamingResponse] = useState('')
  const [chatDisabled, setChatDisabled] = useState(false)
  const bottomRef = useRef(null)

  useEffect(() => {
    // dispatch(clearChat())
    setActiveQuery('')
  }, [dispatch])

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [chatHistory, streamingResponse])

  const filterPayload = (payload) => {
    const filteredPayload = []
    let lastRole = null

    for (let i = payload.length - 1; i >= 0; i--) {
      const current = payload[i]

      if (current.role !== lastRole) {
        filteredPayload.unshift(current)
        lastRole = current.role
      } else if (current.role === 'assistant') {
        filteredPayload.unshift(current)
      }
    }

    return filteredPayload
  }

  const getLast10Messages = () => {
    let recentMessages = chatHistory.slice(-10)
    if (chatContext === 'token') {
      recentMessages = recentMessages.map((obj, i) =>
        i === recentMessages.length - 1
          ? {
              ...obj,
              content: obj.content.replace(
                /token/g,
                `${activeAIToken?.contract_name} (${activeAIToken?.contract_ticker_symbol})`
              )
            }
          : obj
      )
    }
    if (chatContext === 'nft') {
      recentMessages = recentMessages.map((obj, i) =>
        i === recentMessages.length - 1
          ? {
              ...obj,
              content: obj.content.replace(
                /NFT/g,
                `NFT of ${activeAIToken?.description || activeAIToken?.token_id} (${activeAIToken?.asset_contract} on ${activeAIToken?.chainName} chain)`
              )
            }
          : obj
      )
    }
    return recentMessages
  }

  const fetchResponse = useCallback(async () => {
    if (chatHistory.slice(-1)[0]?.role === 'user') {
      setChatDisabled(true)
      try {
        const response = await fetchQueryResponse(filterPayload(getLast10Messages()))
        const reader = response.getReader()
        const decoder = new TextDecoder()

        let responseText = ''
        //eslint-disable-next-line
        while (true) {
          const { done, value } = await reader.read()
          if (done) break
          const chunk = decoder.decode(value, { stream: true })

          const dataLines = chunk.split('\n')
          dataLines.forEach((line) => {
            if (line.trim().startsWith('data:')) {
              const jsonStr = line.trim().slice(5)
              if (jsonStr) {
                try {
                  const jsonObj = JSON.parse(jsonStr)
                  const content = jsonObj.choices[0]?.delta?.content
                  if (content) {
                    responseText += content
                    setStreamingResponse(responseText)
                  }
                } catch (error) {
                  console.error('Error parsing JSON:', error)
                }
              }
            }
          })
        }

        dispatch(addChatMessage({ role: 'assistant', content: responseText }))
        setStreamingResponse('')
        setChatDisabled(false)
      } catch (error) {
        setStreamingResponse(error?.toString()?.slice(7))
        setChatDisabled(false)
      }
    }
    //eslint-disable-next-line
  }, [chatHistory, chatContext, activeAIToken, dispatch])

  useEffect(() => {
    if (chatHistory.slice(-1)[0]?.role === 'user') {
      fetchResponse()
    }
  }, [chatHistory, fetchResponse])

  const handleInputChange = (e) => {
    if (streamingResponse === 'Sorry, something went wrong.') {
      dispatch(
        // Remove the last message if it contains an error
        clearChat()
      )
      setStreamingResponse('')
    }
    setActiveQuery(e.target.value)
  }

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter' && activeQuery) {
      dispatch(addChatMessage({ role: 'user', content: activeQuery }))
      setActiveQuery('')
    }
  }

  return (
    <div className="safle-ai">
      <div className="safle-ai__header">
        <div className="safle-ai__header-left">
          <img
            src={theme === 'light' ? AI_ICON_LIGHT : AIICON2}
            alt="AI Icon"
            className="safle-ai__ai-icon"
          />
          <span className="safle-ai__title">Hi! This is Safle AI</span>
        </div>
        <div className="safle-ai__header-right">
          {chatContext === 'token' && (
            <div className="safle-ai__active-token">
              <img
                src={activeAIToken?.logo_url}
                alt="Active Token"
                className="safle-ai__token-logo"
              />
              <div className="safle-ai__token-details">
                <span className="safle-ai__token-symbol">
                  {activeAIToken?.contract_ticker_symbol?.length > 10
                    ? `${activeAIToken?.contract_ticker_symbol.substring(0, 10)}...`
                    : activeAIToken?.contract_ticker_symbol}
                </span>
                <span className="safle-ai__token-name">
                  {activeAIToken?.contract_name?.length > 10
                    ? `${activeAIToken.contract_name.substring(0, 10)}...`
                    : activeAIToken?.contract_name}
                </span>
              </div>
            </div>
          )}
          <img
            src={CloseIcon}
            alt="Close Icon"
            className="safle-ai__close-icon"
            onClick={() => {
              dispatch(setIsAIActive(false))
              dispatch(setChatContext(''))
              dispatch(setActiveAIToken(null))
            }}
          />
        </div>
      </div>

      <div className="safle-ai__chat-area">
        {chatHistory.length === 0 && (
          <div className="safle-ai__welcome">
            {chatContext === 'nft' && <div className="text-xs">{activeAIToken?.description}</div>}
            <div className="safle-ai__header-right"></div>
            {chatContext === 'nft' && (
              <div className="safle-ai__nft-image-container">
                <img
                  src={activeAIToken?.image_url}
                  onError={(e) => {
                    e.target.onerror = null
                    e.target.src = NFTnotFound
                  }}
                  alt="NFT"
                  className="safle-ai__nft-image"
                />
              </div>
            )}
            <div className="safle-ai__greeting">Hi, How can I help you today?</div>
            <div className="safle-ai__predefined-questions">
              {questions?.[chatContext]?.map((qn, index) => (
                <div
                  key={index}
                  onClick={() => {
                    dispatch(addChatMessage({ role: 'user', content: qn }))
                    setActiveQuery('')
                  }}
                  className="safle-ai__question"
                >
                  {qn}
                </div>
              ))}
            </div>
          </div>
        )}

        {chatHistory?.length > 0 && (
          <div className="safle-ai__messages">
            {chatHistory?.map((chatItem, index) => (
              <div key={index} className={`safle-ai__message safle-ai__message--${chatItem.role}`}>
                <div className="safle-ai__message-content">
                  {chatItem.role === 'assistant' ? (
                    <ReactMarkdown>{chatItem.content}</ReactMarkdown>
                  ) : (
                    chatItem.content
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        {streamingResponse && (
          <div className="safle-ai__streaming-response">{streamingResponse}</div>
        )}

        <div ref={bottomRef} />
      </div>

      <div className="safle-ai__input-area">
        <input
          type="text"
          placeholder="Type your question here"
          value={activeQuery}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          disabled={chatDisabled}
          className={`safle-ai__input ${chatDisabled ? 'safle-ai__input--disabled' : ''}`}
        />
      </div>
    </div>
  )
}

export default SafleAI

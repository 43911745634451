import { combineReducers } from 'redux'
import authReducer from './authReducer'
import themeReducer from './themeReducer'
import modalReducer from './modalReducer'
import safleAIReducer from './safleAiReducer'
import nftReducer from './nftReducer'
import txnReducer from './txnReducer'

const rootReducer = () => {
  return combineReducers({
    auth: authReducer,
    theme: themeReducer,
    modal: modalReducer,
    safleAI: safleAIReducer,
    nfts: nftReducer,
    txns: txnReducer
  })
}

export default rootReducer

import { createSlice } from '@reduxjs/toolkit'
import { fetchNFTs } from '../services/nftService'

const initialState = {
  user: {
    address: '',
    safleId: '',
    ENS: ''
  },
  chains: [],
  activeChains: [],
  visibleNFTs: [],
  hasMore: [],
  nfts: [],
  loading: false,
  error: null
}

const nftSlice = createSlice({
  name: 'nfts',
  initialState,
  reducers: {
    setActiveChains: (state, action) => {
      state.activeChains = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNFTs.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchNFTs.fulfilled, (state, action) => {
      state.loading = false
      if (action.payload) {
        state.nfts = [...state.nfts, action.payload]
      }
    })
    builder.addCase(fetchNFTs.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  }
})

export const { setActiveChains, setVisibleNFTs, setHasMore } = nftSlice.actions

export default nftSlice.reducer
